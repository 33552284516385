@import "../../../styles/global.scss";

.feedback-wrapper{

  .countDownWrapper{
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    height: 5px;
  }

  .alert-feedback{
    border-top-left-radius: 0 !important;
  }

  .countDown{
    position: absolute;
    top: 0;
    width: 0;
    animation: countingDown 3s linear infinite;
    background: #6bca6c;
    height: 5px;
    border-radius: 3px 3px 0 0;
    animation-delay: 0.5s;
  }

  @keyframes countingDown {
    0% {
      width: 100%;
    }

    100% {
      width: 0;
    }
  }

  textarea:focus, textarea:active {
    box-shadow: none;
    border: 1px solid $orange;
  }

  .modal-body{
    overflow: hidden;
  }

  .text-description{
    h3{
      font-family: Roboto-Light, serif;
    }
    p{
      font-size: 16px;
    }
  }

  .container{
    padding: 0;
  }

  .score-wrapper{
    margin-bottom: 30px;
    margin-top: 25px;
    flex-wrap: nowrap;
    max-height: 215px;

    .col:last-child .score-tile{
      img {
        border-radius: 0 5px 5px 0 !important;
      }
      border-radius: 0 5px 5px 0 !important;
      background-color: #fa5462;
    }

    .col:nth-child(4) .score-tile{
      background-color: #fa834f;
    }

    .col:nth-child(3) .score-tile{
      background-color: #fbcf3e;
    }

    .col:nth-child(2) .score-tile{
      background-color: #9fd77a;
    }

    .col:first-child .score-tile{
      img {
        border-radius: 5px 0 0 5px !important;
      }
      border-radius: 5px 0 0 5px !important;
      background-color: #6acb6c;
    }

    .score-tile{
      position: relative;
      cursor: pointer;
      height: 100%;

      img{
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      span{
        display: block;
        width: 100%;
        font-size: 12px;
        text-align: center;
        color: $black-carbon;
        opacity: 0;
      }

      &:hover{
        span{
          opacity: 1;
        }
      }

      &.active{
        img{
          padding: 0 0;
        }
        span{
          transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          opacity: 1;
        }
      }
    }

  }
  .tile-selector{
    width: calc(100% / 5 + 20px);
    position: absolute;
    top: -3px;
    right: -8px;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    opacity: 1;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.4));
  }

  .tile-selector.inactive{
    right: calc(-8px + -20% + 30px);
    opacity: 0.5;
  }
}
