@import "../../../styles/global";


.mood-over-layer-wrapper{

  .mood-btn{
    width: 40px;
    height: 40px;
    margin-right: 15px;
    padding: 0;
    background: whitesmoke !important;

    i{
      color: $black-carbon;
    }

    &.no-mood{
      border: 1px solid #e6e6e6;
      background-color: #f8f9fa;
    }

    &:active, &:focus{
      border: 0 solid black !important;
      box-shadow: none !important;
      img{
        border-radius: 5px;
      }
    }

    img{
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }


}

#popover-positioned-bottom{
  .popover-header{
    margin-top: 0;
  }
}


.popup-mood-wrapper {
  float: right;
  text-align: center;
  height: 40px;
  width: 200px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  .mood-options{
    display: flex;

    .mood-item {
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin: auto;

      img{
        border: 2px solid transparent;
        border-radius: 5px;
        max-width: 30px;
      }

      &.active{
        img{
          border: 2px solid $black-carbon;
        }
        &:hover {
          img{
            border: 2px solid $black-carbon;
          }
        }
      }

      &:hover {
        img{
          border: 2px solid $border-line;
        }
      }
    }



  }
}
