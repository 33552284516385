$sidebar-bg-color: #27292d !important;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #3c3e42 !default;
$submenu-bg-color-collapsed: #3c3e42 !default;
$icon-bg-color: #3c3e42 !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

@import '../../../../../node_modules/react-pro-sidebar/dist/scss/styles';

.pro-inner-list-item{
  .pro-inner-item{
    &:before{
      display: none !important;
    }
    .pro-icon-wrapper{
      display: block !important;
      background-color: $sidebar-bg-color !important;
    }
  }
}

.pro-icon{
  animation: none !important;
}


.pro-sidebar-header{
  height: 57px !important;
  >div{
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0 20px;
    img{
      margin: auto;
      height: 40px;
      width: 40px;
    }
    span{
      margin: auto 0;
      width: 100%;
    }
  }
}
.sidebar-btn-wrapper{
  height: 100%;
  padding: 12px 22px;
  .sidebar-btn{
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: #3E454B;
    display: flex;
    padding: 0;
    margin: auto 0 auto auto;
    i{
      margin: auto;
      font-size: 14px;
    }
  }
}
