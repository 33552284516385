@import "../../../styles/global";

.distribution-wrapper{
  display: flex;
  width: 100%;
  position: relative;
  transition: all  0.5s ease-in-out;

  &.open{
    svg{
      width: 100%;
      height: 120px;
    }
  }

  .legend{
    background: rgba(0,0,0,0.1);
    border-radius: 5px;
    position: absolute;
    left: 10px;
    top: 20px;
    padding: 5px;
    .legend-item{
      text-transform: capitalize;
      display: flex;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 5px;

      &:before{
        width: 10px;
        height: 10px;
        border-radius: 2px;
        content: " ";
        display: block;
        margin-right: 5px;
        box-shadow: 1px 1px 1px #585858;
      }
    }

    .legend-item:nth-child(5){
      &:before{
        background-color: #fa5462;
      }
    }

    .legend-item:nth-child(4){
      &:before{
        background-color: #fa834f;
      }
    }

    .legend-item:nth-child(3){
      &:before{
        background-color: #fbcf3e;
      }
    }

    .legend-item:nth-child(2){
      &:before{
        background-color: #9fd77a;
      }
    }

    .legend-item:nth-child(1){
      &:before{
        background-color: #6acb6c;
      }
    }

  }

  .expander{
    background: $orange;
    cursor: pointer;
    color: white;
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    border-radius: 100%;
    right: 55px;
    top: -6px;
    box-shadow: 1px 1px 1px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
    z-index: 10;

    i{
      margin: auto;
    }
  }

}
