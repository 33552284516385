@import "../../../styles/global";

.team-radar-wrapper{
  overflow: hidden;
  padding: 10px;
  position: relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-line;
  background: #e6e6e6;
  border-radius: 5px;
  margin-top: 10px;
  height: 450px;
  width: 450px;
  svg{
    margin: auto;

    text{
      stroke-width: 0.5px;
      stroke: #b4b4b4;
    }
  }

  .hidden-message{
    position: absolute;
    top: 9px;
    z-index: 1;
    font-family: Roboto-Black, serif;
    color: #01121a;
    left: 10px;
    background: #f3f3f3;
    padding: 5px 10px;
    max-width: calc(100% - 56px);
    border-radius: 5px;
  }

  .vote-anonymous{
    filter: grayscale(0.7);
    &:before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: transparent;
      z-index: 1;
      left: 0;
      border-radius: 5px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238d8d8d' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}



