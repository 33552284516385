@import "../../../styles/global";

.home-wrapper{
  padding: 0;

  .login-navbar {
    margin-bottom: 50px;
    > .col {
      padding: 0 !important;
    }

    background: $orange;

    margin-left: -15px;
    margin-right: -15px;
    min-height: 50px;

    .navbar-brand{
      background: #4c4236;
      width: 50px;
      height: 50px;
      display: flex;
      margin: -7px 5px -7px -15px ;

      img{
        padding: 0;
        margin: auto;
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7));
      }
    }
  }

  .btn-start{
    margin: 50px auto;
    .col{
      display: flex;
    }
    a{
      margin: auto;
        background: $orange;
        border: $orange;
        color: white;
      font-family: Roboto-Light, serif;
      font-size: 18px;
    }
  }
}
