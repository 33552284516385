.moodModal-wrapper{

  h5{
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .container-fluid{
    padding: 0;
  }

  .modal-90w{
    width: 90%;
    max-width: 600px;
    margin: auto;
  }


  .score-wrapper{
    display: flex;
    flex-wrap: nowrap;


    .col:last-child .score-tile{
      img {
        border-radius: 0 5px 5px 0 !important;
      }
      border-radius: 0 5px 5px 0 !important;
    }

    .col:first-child .score-tile{
      img {
        border-radius: 5px 0 0 5px !important;
      }
      border-radius: 5px 0 0 5px !important;
    }

    >.col{
      width: 20% !important;
    }
    .score-tile{
      position: relative;
      cursor: pointer;
       .tile-selector{
         position: absolute;
         top: 10%;
         left: 0;
         display: none;
       }
        span{
          opacity: 0;
          text-align: center;
          display: block;
          width: 100%;
          text-transform: capitalize;
          font-size: 12px;
        }
    }

    .score-tile:hover , .score-tile.active{
      .tile-selector{
        display: block;
      }
      span{
        opacity: 1;
      }
    }

  }
}
