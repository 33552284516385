$border-line: #e6e6e6;
$border-active: #f39820;
$background-active: #4c4236;
$background-deck: #f2f2f2;
$default-text-color: #4c4c4c;
$black-carbon: #333333;

$orange: #f39820;

.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    background-color: whitesmoke;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $orange;
  }
}

.shadow {
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
  0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.btn-locale{
  float: right;
  margin-left: 20px;
  .flag{
    width: 15px;
    margin-right: 10px;
  }

  .dropdown-menu{
    padding: 0;
  }
  .btn-primary{
    background-color: white !important;
    border-color: $border-line !important;
    color: $default-text-color !important;
  }

  .dropdown-divider{
    margin: 0;
  }

  .dropdown-item{
    padding: 8px;
  }

  .dropdown-item.active{
    background: $orange;
  }

  .dropdown-item:active{
    background: $border-line;
    color: $default-text-color;
  }
}

.tooltip-orange{
  opacity: 1 !important;
  background-color: $orange !important;
  z-index: 100000;
  box-shadow: 2px 3px 1px rgba(60, 64, 67, 0.3),
  0 1px 3px 1px rgba(60, 64, 67, 0.15);

  &:after{
    border-color: transparent !important;
  }
}

.team-loader{
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  z-index: 10000;

  img{
    width: 100px;
    height: 100px;
    margin: auto;
    -webkit-animation:spin 3s ease-in-out infinite;
    -moz-animation:spin 3s ease-in-out infinite;
    animation:spin 3s ease-in-out infinite;
  }
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg);} }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg);} }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg);} }

}

.team-loader.small{
  background: transparent !important;

  img{
    width: 50px !important;
    height: 50px !important;
    margin: auto;

  }


}

.btn-primary{
  background-color: $orange !important;
  color: white;
  border: $orange;

  &:hover{
    filter: brightness(105%);
  }
}


.modal-header{
  background: $orange;
  color: white;
  border-bottom: 0 !important;
  .modal-title {
    line-height: 36px;
    font-size: 20px;
  }
  .close{
    color: white !important;
    opacity: 1;

    &:active, &:focus{
      box-shadow: none !important;
      border: 0 !important;
      outline: none !important;
    }
  }
}

.modal-body{

}
