@import "../../../styles/global";
.chat-widget-wrapper{

  &.expanded{
    height: calc(100%  - 25px)!important;
    max-height: calc(100%  - 35px)!important;
    right: 10px;
    box-shadow: none;
    transition: all 0.5s ease-in-out;

    .chat-controls.open{
      height: 100%;

      textarea.expanded{
        height: 290px !important;
        min-height: 290px !important;
      }
    }

    .chat-widget-body.scrollbar.closed{
      height: calc(100% - 390px) !important;
      max-height: calc(100% - 390px) !important;
    }

  }

  max-width: 336px;
  width: 336px;
  max-height: 400px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.15);


  position: absolute;
  bottom: 0;
  right: 15px;
  z-index: 100;

  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 576px) {
    max-width: 285px;
    width: 285px;
    right: 15px !important;
  }

  &.open{
    height: 400px;
  }

  &.closed{
    height: 36px;
  }

  .chat-widget-header{
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: row;
    background: #3c3e42;
    height: 36px;
    border: 1px solid #3c3e42;
    border-bottom: none;
    padding: 0 5px 3px;

    .chat-icon{
      margin: auto 0 auto 5px;
      color: white;
      font-size: 20px;
       i{
         margin: auto;
       }
    }

    .chat-new-messages-counter{
      margin: 2px auto auto 10px;

      .title{
        color: white;
        font-size: 16px;
        padding-top: 2px;
      }

      .badge.badge-primary{
        margin-top: 6px;
        background-color: $orange !important;
      }

    }
    .toggle , .expander{
      width: 25px;
      height: 25px;
      display: flex;
      float: right;
      background: #eaeaea;
      border-radius: 100%;
      cursor: pointer;
      i{
        margin: auto;
      }
    }

    .toggle{
      margin: auto 5px;
    }

    .expander{
      margin: auto 5px auto auto;
      opacity: 0.5;
      &:hover{
        opacity: 1;
      }
    }
  }
  .chat-widget-body{
    background: white;
    border: 1px solid $border-line;
    border-bottom: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 10000px;
    height: calc(100% - 162px);
    position: relative;


    .scroller{
      position: sticky;
      top: calc(100% - 35px);
      z-index: 10;
      left: 100%;
      background: rgba(0,0,0,0.2);
      padding: 5px;
      width: 30px;
      border-radius: 5px 0 0 5px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      height: 29px;
      transition: all 0.5s ease-in-out;
      margin-top: -29px;
      color: $default-text-color;

      &.shown{
        opacity: 1;
      }

      &.hidden{
        opacity: 0;
      }

      &.withNumber{
        width: 60px;
      }

      i{
        margin: auto 2px auto 0;

      }

      span{
        text-align: center;
        margin: auto;
        width: 100%;
      }

      &:hover{
        background: rgba(0,0,0,0.5);
        color: white;
      }
    }

    .fake-toast{
      border: none;
      height: 0;
    }

    &.closed{
      max-height: 0 !important;
      height: 0 !important;
      overflow: hidden;
      padding: 0;
    }

  }

  .chat-controls{
    background: white;
    padding: 0 0 10px 0;
    border: 1px solid $border-line;
    position: relative;

    .btn-expand-text{
      position: absolute;
      right: 10px;
      z-index: 1;
      top: 10px;
      height: 25px;
      width: 25px;
      opacity: 0.5;
      border: 1px solid $border-line;
      border-radius: 100%;
      display: flex;
      padding: 0;

      i{
        margin: auto;
      }

      &:hover{
        opacity: 1;
      }

    }

    &.closed{
      max-height: 0 !important;
      height: 0 !important;
      overflow: hidden;
      padding: 0;
    }


    textarea{
      border: none;
      border-bottom: 35px;
      min-height: 60px;
      border-radius: 0;
      border-top: 2px solid white;
      border-bottom: 1px solid $border-line;
      resize: none;
      padding-right: 30px;

      &.whites{
        white-space: pre-line;
      }

      &.expanded{
        min-height: 290px;
      }

      &:active, &:focus{
        box-shadow: none;
        border-top: 2px solid $orange;

      }
    }

    .send-btn{
      float: right;
      margin-right: 10px;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      padding: 0;
      display: flex;
      i{
        margin: auto 8px;
      }
    }
  }

}

.chat-toast-item:last-child{
  margin-bottom: 0 !important;
}
.chat-toast-item{
  border: none;
  box-shadow: none;
  margin-bottom: 0 !important;
  padding-top: 10px;
  border-bottom: 1px solid $border-line;
  border-radius: 0 !important;

  .toast-header{
    display: flex;
    border: none;
    .chat-avatar{
      margin-right: 5px;
      img{
        width: 25px;
        height: 25px;
        background-color: #eaeaea;
        border-radius: 100%;
        overflow: hidden;
        object-fit: contain;
        padding-top: 2px;
      }
    }

    .owner{
      display: block;
      font-size: 14px;
      color: $default-text-color;
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    small{
      margin: auto 0 auto auto;
      text-align: right;
    }
  }

  .toast-body{
    margin: 5px 10px 10px 40px;
    border-radius: 5px;
    background-color: #eaeaea;

    a{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $orange;
    }
  }
}

.enterCheck{
  padding-left: 10px;
  margin-top: 7px !important;
  font-size: 12px;
}



