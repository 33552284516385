@import "../../../styles/global";

.cloud-tag-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-line;
  background: #e6e6e6;
  border-radius: 5px;
  margin-top: 10px;
  height: 400px;
  svg{
    margin: auto;

    text{
      stroke-width: 0.5px;
      stroke: #b4b4b4;
    }
  }
}

