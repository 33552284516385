@import "../../../styles/global";

.action-card-wrapper {
  padding: 5px !important;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
  margin: auto;
  overflow: hidden;

  &.condensed{
    width: 50px;
    padding: 0 !important;
    margin-bottom: 5px;
    margin-right: 5px;
    .action-card {
      padding: 0;
      .image-action-wrapper {
        img {
          width: 38px;
          height: 30px;
        }
      }
      .action-label{
        display: none;
      }
    }
  }

  .action-card {
    transition: all 0.5s ease-in-out;
    background: white;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid $border-line;
    cursor: pointer;

    .col {
      padding: 0 !important;
      display: flex;
    }

    .image-action-wrapper {
      display: flex;

      img {
        max-height: 35px;
        object-fit: contain;
        width: 60px;
        height: 35px;
        margin: auto;
      }
    }

    .action-label {
      width: 100%;
      display: block;
      text-align: center;
      margin: auto auto 0 auto;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      padding-top: 5px;
    }
  }
}

.action-card-wrapper.active {

  .action-card{
    transition: background 0.5s ease-in-out;
    background: $border-active;
    border: 1px solid $border-active;
    box-shadow: 0 1px 2px rgba(60,64,67, 0.3), 0 1px 3px 1px rgba(60,64,67, 0.15);

    .action-label {
      color: white;
    }

    .image-action-wrapper{
      -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
      filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    }
  }
}
