.poll-cloud-wrapper{
  .badge-cloud{
    word-break: break-all;
    white-space: normal;
    padding: 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    text-align: left;
    font-size: 12px;
    line-height: 15px;

    i{
      float: right;
      line-height: 15px;
    }
  }
}
