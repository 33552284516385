@import "../../../styles/global.scss";


.app-navbar{
  background: white;
  border-bottom: 1px solid $border-line;
  padding: .5rem 5px;

  .tool-action-wrapper{
    float: right !important;
    height: 40px;
    margin: 0 0 auto auto;
    display: block;

    .dropdown-item{
      i{
        margin-right: 5px;
      }
    }

    .btn-locale{
      height: 40px;
      margin-left: 0 !important;
      >button{
        white-space: nowrap;
        display: inline-block;
        min-width: 40px;
        box-shadow: none !important;
        border: 1px solid $border-line;
        &:before{
          content: "\f141";
          width: 10px;
          font-family: 'Font Awesome 5 Free', serif;
          font-weight: 900;
          background: transparent;
        }

        &:after{
          display: none;
        }
      }
    }

    .btn-locale.show{
      >button{
        background: $orange !important;
        box-shadow: none !important;
        color: white !important;
        outline: 0;
      }
      >button:focus, >button:active{
        box-shadow: none !important;
        outline: 0 !important;
      }


    }
  }

  input{
    cursor: pointer;
  }

  .active{
    input{
      background: white !important;
      border: 1px solid white !important;
      color: $orange;
    }
  }

  .languages-wrapper{
    margin-top: 10px;
    display: grid;
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    button{
      transition: all 0.2s linear;
      display: inline-block;
      background: white;
      border: 1px solid $border-line;

      span{
        display: inline-block;
        width: 70px;
        text-align: left;
      }

      &.active{
        background: $orange !important;
        color: white !important;
      }

      &:hover{
        background: $orange !important;
        color: white !important;
      }

    }
  }

  .sorting-wrapper{
    margin-top: 10px;
    display: grid;
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    button{
      transition: all 0.2s linear;
      display: inline-block;
      background: white;
      border: 0;
      text-align: left;
      padding-left: 20px;
      i{
        color: #2f3336;
      }

      i.active{
        color: $orange;
      }

      span{
        display: inline-block;
        width: 70px;
        text-align: left;
      }

      &.active{
        background: $orange !important;
        color: white !important;
      }

      &:hover{
        background: #f8f9fa;

        i{
          color: $orange;
        }
      }

    }
  }

  .user-info{
    display: flex;
    flex-direction: row;
    min-height: 50px;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid $border-line;
    .info{
      display: flex;
      flex-direction: column;

      .name{
        margin: auto auto 0 0;
      }
      .room{
        font-family: Roboto-Regular, serif;
        margin: 0 auto auto 0;
      }
    }

    .tool-bar-avatar{
      margin-right: 10px;
      border: 2px solid $border-line;
      border-radius: 100%;
      height: 50px;
      width: 50px;
      overflow: hidden;
      background: #eaeaea;
      img{
        width: 100%;
        height: 100%;
        object-position: 0 5px;
      }
    }
  }

  .mood-wrapper {
    float: right;
    text-align: center;
    height: 50px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid $border-line;

    .mood-options {
      display: flex;


      .mood-item:first-child {
        margin-left: 0;
      }

      .mood-item:last-child {
        margin-right: 0;
      }

      .mood-item {
        width: 22px;
        height: 22px;
        cursor: pointer;
        margin: auto;

        img{
          border: 2px solid transparent;
          border-radius: 5px;
          max-width: 22px;
        }

        &.active{
          img{
            border: 2px solid $black-carbon;
          }
          &:hover {
            img{
              border: 2px solid $black-carbon;
            }
          }
        }

        &:hover {
          img{
            border: 2px solid $border-line;
          }
        }
      }
    }

    .mood-label{
      padding: 5px 0 0 0;
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-family: Roboto-Regular, serif;
      white-space: nowrap;

    }
  }
}


.timer-nav-wrapper{
  margin-right: 10px;
}
