/*
position: absolute;
right: 15px;
top: 0;
height: calc(100%);
background: red;
width: 375px;*/


@import "../../../styles/global";
.notification-center-wrapper{
  //transition: width 0.5s ease-in-out;
  width: 320px;
  min-width: 320px;
  overflow: hidden;
  background: white;
  position: relative;
  top: 0;

  z-index: 2;
  @extend .shadow;

  &.closed{
    transition: top 0.5s ease-in-out;
    width: 40px;
    min-width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    top: -15px;
    border-radius: 100%;



    .hamburger-toggle{
      margin-right: 3px;
      display: none !important;
    }

    .sidebar-header{
      height: 41px;
      img{
        display: block;
        margin: auto;
        width: 24px;
      }

      span{
        display: none !important;
      }
    }

  }

  .sidebar-header{
    background: #3c3e42;
    padding: 5px;
    height: 36px;
    display: flex;
    width: 100%;

    img{

      height: 24px;
      cursor: pointer;
      width: 38px;
    }

    span{
      width: calc(100% - 65px);
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      color: white;
      padding: 3px 10px 0 5px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
    }

    .hamburger-toggle{
      font-size: 22px;
      display: inline-block;
      width: 1em;
      height: 1em;
      padding: 0;
      margin-top: 2px;
      cursor: pointer;
      //transition: all .2s ease-in-out;
      //-moz-transition: all .2s ease-in-out;
      //-webkit-transition: all .2s ease-in-out;
      vertical-align: middle;
      border: 0 none;
      background: transparent;
      float: right;

      &:before, &:after, .icon{
        content: " ";
        display: block;
        width: 100%;
        height: 4.39px;
        margin: 0 0 .2em;
        //transition: transform .2s ease-in-out;
        //-moz-transition: transform .2s ease-in-out;
        //-webkit-transition: transform .2s ease-in-out;
        border-radius: .25em;
        -moz-border-radius: 25em;
        -webkit-border-radius: .25em;
        background: $background-deck;
      }

      &.closed{
        transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);

        &:before{
          width: .6em;
          transform:   translateX(-2px) translateY(.2em) rotate(-45deg);
          -moz-transform: translateX(-2px)    translateY(.2em) rotate(-45deg);
          -webkit-transform: translateX(-2px)    translateY(.2em) rotate(-45deg);
        }

        &:after{
          width: .6em;
          transform:  translateX(-2.7px) translateY(-3px) rotate(45deg);
          -moz-transform:   translateX(-2.7px) translateY(-3px) rotate(45deg);
          -webkit-transform:  translateX(-2.7px) translateY(-3px) rotate(45deg);
        }
      }
    }
  }


  .notification-wrapper{
    padding: 5px 5px 10px 5px;
    overflow: auto;
    height: calc(100% - 35px);
    @extend .scrollbar;


    .nav.nav-tabs{
      padding: 5px 5px 0;
      margin: -5px -5px 5px;
      position: sticky;
      top: -5px;
      z-index: 5;
      background: white;

/*      #notification-tabs-tab-polls:before{
        content: "\f466";
        display: inline-block;
        font-family: "Font Awesome 5 Free", serif;
        font-weight: bold;
        margin-right: 5px;
        font-size: 12px;
      }

      #notification-tabs-tab-messages:before{
        content: "\f1d8";
        display: inline-block;
        font-family: "Font Awesome 5 Free", serif;
        font-weight: bold;
        margin-right: 5px;
        font-size: 12px;
      }*/


      a{
        color: rgba(0,0,0,.87);
        margin-right: 5px;
      }

      a:active, a:focus{

        box-shadow: none;
        outline: 0;
      }

      .nav-item.nav-link.active{
        color: $orange;
      }
    }

    .toast:first-child{
      margin-top: 5px;
    }
    .toast{
      box-shadow: none;

      .toast-header{
        background: #f2f2f2;
      }

      .toast-body{
        overflow: hidden;

        a{
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $orange;
        }
      }
    }


    .direct-result{
      border: 1px solid $border-line;
      padding: 5px;
      text-align: center;
      font-family: Roboto-Bold, serif;
      min-width: 75px;
      line-height: 12px;
    }

    .text-toast-item.activeMsn{
      border: 1px solid $orange;
      transform: scale(1);
      animation: pulse-black 2s infinite;

      .toast-header{
        background: $orange;
        color: white;
      }

      @keyframes pulse-black {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(243, 152, 32, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(243, 152, 32, 0);
        }

      }
    }

    .poll-toast-item{
      .green{
        color: #0ea432;
      }
      .red{
        color: #ff004e;
      }

      .vote-winner-wrapper{
        display: grid;
        gap: 5px 5px;
        grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
        grid-auto-rows: 27px;

       img{
          width: 40px;
          height: 27px;

        }

      }

      .btn-see-voting{
        margin: auto 0 auto auto;
      }
    }


  }

  .bell2{
    display:block;
    height: 50px;
    width: 100%;

    -webkit-animation: ring 3s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 3s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 3s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
  }

  @-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }

    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
  }

  @-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }

    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
  }

  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    width: calc(100% - 30px);
    position: absolute;
    top: -65px;
    right: 15px;
    height: calc(100% + 65px);
    z-index: 10;

    .toast{
      width: 100%;
      max-width: 100%;

      .toast-header{
        background: #f2f2f2;
      }

    }

  }

}


