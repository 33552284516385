@import "../../styles/global";

html, body, #root{
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 0 !important;
}

img{
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

p{
  font-family: Roboto-Regular, serif;
}

h1, h2, h3, h4 , h5, h6{
  font-family: Roboto-Light, serif !important;
}

.app-wrapper{
  height: auto;
  min-height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-content: stretch;
  background-color: #f6f6f6;

  font-family: Roboto-Regular, serif;
  @extend .scrollbar;
  overflow-y: auto;
  overflow-x: hidden;

  .view-wrapper{
    display: flex;
    flex-direction: row;

    .side-bar-wrapper{

    }

    .guest-list-wrapper{

    }
  }
}


body{
  .app-wrapper{
    transition: filter 0.5s ease-in-out;
    filter: blur(0px);
  }
}

body.modal-open{
  .app-wrapper{
    transition: filter 1s ease-in-out;
    filter: blur(4px);
  }
}



/* Small devices (landscape phones, 576px and up) */
@media (max-width: 375px) {

  .mood-wrapper{
    padding-right: 8px !important;
    margin-right: 8px !important;
  }

  .user-info{
    width: calc(100% - 214px);
    padding-right: 8px !important;
    margin-right: 8px !important;
    .info{
      width: 100%;
      .name{
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .room{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

}





