@import "../../../styles/global";
.pagination-wrapper{
  margin-top: 10px;
  margin-bottom: 10px;

  .page-item{
    a{
      color: #494f57;
      outline: none !important;
      box-shadow: none !important;
    }

    &.active{
      .page-link{
        background: $orange;
        border-color: $orange;
      }

    }
  }
}
