

.admin-wrapper{
  padding: 0 !important;
  height: 100vh;
  font-family: Roboto-Light, serif;

  .panel-main-portlet{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .card-form-portlet{
    .card-header{
      padding: 15px;
    }
    .card-body{
      padding: 15px;
    }
    .card-footer{
      padding: 15px;
      button, a{
        margin-right: 10px;
      }
    }
  }
}
