@import "../../../styles/global";

.voting-info{

  margin-bottom: 10px;
  border-bottom: 1px solid $border-line;
  padding: 0 0 5px 0;
  h5{
    float: left;
    margin-bottom: 0;
    font-family: Roboto-Light, serif;
  }
  span{
    float: right;
  }
}

.results-wrapper.voting-v-bars{

  .overrule-wrapper{
    margin-top: 5px;
  }

  .vote-overruled .col{
    overflow: hidden;
    .overrule-message{
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;

      div{
        margin: auto;
        font-size: 30px;
        line-height: 35px;
        font-family: 'Roboto-Black', serif;
        text-shadow: 1px 1px 1px black;
        text-align: center;
        color: #28a745;
      }
    }

    .vote-item-wrapper{
      opacity: 0.2;
      &:before, &:after{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent;
        z-index: 1;
        left: 0;
        border-radius: 5px;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238d8d8d' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
      }

      &:after{
        transform:scaleX(-1);
        top: 0
      }
    }



  }

  .hidden-message{
    position: absolute;
    top: 9px;
    z-index: 1;
    font-family: Roboto-Black, serif;
    color: #01121a;
    left: 10px;
    background: #f3f3f3;
    padding: 5px 10px;
    max-width: calc(100% - 56px);
    border-radius: 5px;
  }

  &.vote-anonymous .vote-item-wrapper{
    filter: grayscale(0.7);
  }

  &.vote-anonymous .vote-item-wrapper:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 1;
    left: 0;
    border-radius: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238d8d8d' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  .vote-item-wrapper{

    .vote-item:first-child {
      .score-wrapper {
        border-top-left-radius: 5px;
      }
      .card-wrapper{
        border-bottom-left-radius: 5px;
      }

      .voter-wrapper{
        border-left: 1px solid $border-line;
        border-bottom-left-radius: 5px;
      }
    }

    .vote-item:last-child {
      .score-wrapper {
        border-top-right-radius: 5px;
      }
      .card-wrapper{
        border-bottom-right-radius: 5px;
      }

      .voter-wrapper{
        border-bottom-right-radius: 5px;
      }
    }


  }

  .vote-item-wrapper{

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    justify-content: stretch;
    align-content: stretch;
    height: 100%;

    background: #e6e6e6;
    border-radius: 5px;

    .vote-item{
      transition: all 0.5s ease-in-out;
      display: flex;
      flex-direction: column;
      height: 100%;

      .score-wrapper{
        height: 190px;
        display: flex;
        padding: 50px 10px 0 10px;
        background: #e6e6e6;
        border-radius: 0;


        .score{
          margin: auto auto 0 auto;
          width: 100%;
          border-radius: 5px 5px;
          border: 2px solid #221f1f;
          transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

          .stats-wrapper{
            float: right;
            text-align: right;
            width: calc(100% - 50px);
            padding: 5px;
            display: flex;
            flex-direction: column;
            min-width: 38px;
            color: white;
            font-family: Roboto-Black, serif;
            position: relative;
            top: -49px;

            .percentage{
              font-size: 16px;
              text-align: right;
              text-shadow: 1px 1px 1px black;
            }
            .total-votes{
              text-align: right;
              font-size: 10px;
              white-space: nowrap;
              color: black !important;
            }

          }

        }
      }


      .card-wrapper{
        padding: 10px;
        background: #e6e6e6;
        display: flex;
        img{
          height: 35px;
          width: calc(100% - 20px);
          padding: 5px 0;
          margin: auto;
        }

        .tagValue{
          height: 35px;
          width: calc(100% - 20px);
          padding: 5px 0;
          margin: auto;
          text-align: center;
          font-family: Roboto-Bold, serif;
        }

        i{
          margin: auto;
        }
      }

      .voter-wrapper{
        display: grid;
        grid-gap: 5px 5px;
        gap: 5px 5px;
        grid-template-columns: repeat(auto-fill, minmax(35px, 1fr));
        grid-auto-rows: 35px;
        padding: 10px;
        border-right: 1px solid $border-line;
        height: calc(100% - 246px);
        background: white;

        >div{
          display: flex;
        }

        .voter-avatar{
          width: 35px;
          height: 35px;
          transition: all 0.5s ease-in-out;
          border: 2px solid #e6e6e6;
          z-index: 10;
          background: white;
          border-radius: 100%;
          object-fit: contain;
          object-position: 0 3px;
          margin: auto;
        }
      }

    }
  }
}





