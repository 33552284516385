@import "../../../styles/global";
.poll-team-radar-wrapper{
  .voting-radar-deck-wrapper{
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    background-color: #e6e6e6;
    width: 100%;
    display: grid;
    grid-gap: 5px 5px;
    gap: 5px 5px;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-auto-rows: minmax(50px, 1fr);

    >div{
      padding: 2.5px;
    }

    .voting-card{
      border: 1px solid #b4b4b4;
      background: #f3f3f3;
      border-radius: 5px;
      padding: 5px 5px 0 5px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      img{
        width: 100%;
        height: calc(100% - 30px);
        object-fit: contain;
        padding: 5px;
        min-width: 30px;
        min-height: 25px;
        max-height: 40px;
      }

      label{
        text-align: center;
        font-size: 12px;
        width: 100%;
      }
    }

    .voting-card.active{
      background: $orange;
      label{
        color: white;
      }
    }
  }

}

.slider-voted{
  background-color: #f39820 !important;
  border-color: #f39820 !important;
  color: white;
}
