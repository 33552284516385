@import "../../../styles/global";

.mood-trend-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: #a7a5a5;
  .mood-item{
    transition: all 0.5s ease-in-out;
    width: 0;
    height: 100%;
    cursor: pointer;
  }

  .mood-one{
    background-color: #fa5462;
  }

  .mood-two{
    background-color: #fa834f;
  }

  .mood-three{
    background-color: #fbcf3e;
  }

  .mood-four{
    background-color: #9fd77a;
  }

  .mood-five{
    background-color: #6acb6c;
  }
}

