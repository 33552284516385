@import "../../../styles/global.scss";

.logout-wrapper{

  .modal-body{
    padding: 10px 0 35px 0;
  }

  .text-description{
    margin-bottom: 15px;
    h3{
      font-family: Roboto-Light, serif;
    }
  }

  .option-tile{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid $border-line;

    span{
      margin: 0 auto auto auto;
      padding: 20px;
      height: 100%;
      display: block;
    }
    button{
      margin: 0;
      border-radius: 0 0 5px 5px;
    }
  }
}

