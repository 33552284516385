@import "../../../styles/global";

.share-fb{
  background: #1877f2 !important;
  padding: 1px 4px 6px 4px;
  border-radius: 5px;
  border: 1px solid #1877f2;
  &:hover{
    cursor: pointer;
    border: 1px solid white;
  }
}

.share-twitter{
  background: #1da1f2;
  border: 1px solid #1da1f2;
  &:hover{
    background: #1da1f2;
  }
}

.share-linkedin{
  background: #0073b1;
  border: 1px solid #0073b1;
  &:hover{
    background: #0073b1;
  }
}


.share-btn{
  height: 31.5px;
  margin-left: 10px;
  padding: 0 15px;
  display: flex;
  color: white;

  font-size: 11px;
  font-family: Roboto-Black, serif;
  border-radius: 5px;

  &:hover{
    color: white;
    border: 1px solid white;
  }

  span{
    margin: auto;
    padding-left: 5px;
    line-height: 3px;
  }
  i{
    margin: auto;
    line-height: 8px;
    font-size: 14px;
  }
}




.main-login-wrapper {
  position: absolute;
  padding: 0 0 10px 0;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 600px;
  z-index: 100000000;
  background-color: #fdfdfd;
  overflow-x: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f4f4f4' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @extend .scrollbar;

  h4 {
    font-family: Roboto-Light, serif;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  .login-navbar {

    > .col {
      padding: 0 !important;
    }

    background: $orange;
    min-height: 50px;
    padding: 0 15px 0 0;

    .navbar-brand{
      background: #3c3e42;
      width: 50px;
      height: 50px;
      display: flex;
      margin: 0;

      img{
        padding: 0;
        margin: auto;
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7));
      }
    }
  }


  .portlet-wrapper{
    display: block;
    width: 100%;
    color: $default-text-color;


    .newsletter-wrapper{
      min-width: 210px;
      padding: 15px 0;
      background: white;
      box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
      border-radius: 5px;
      margin: 15px auto 10px;
      width: 100%;
      max-width: 800px;
      overflow: hidden;
      min-height: 200px;
      display: block;

      h3 {
        background: #3c3e42;
        color: white;
        margin: -30px -30px 15px;
        padding: 15px 30px;

        i{
          color: $orange;
        }
      }

      form{
        padding: 0 15px;
        margin: 15px auto auto ;
      }

      #mergeRow-gdpr{
        background: #e6e6e6;
        border-radius: 5px;
        font-size: 12px;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        max-height: 0;
        overflow: hidden;
      }

      input {
        font-family: Roboto-Regular, serif;
      }

      input:focus {
        box-shadow: none;
        border: 1px solid $orange;
      }

      button:disabled{
        background-color: #828a91 !important;
        cursor: not-allowed;
      }

      button {
        transition: all 0.5s ease-in-out;
        background-color: $orange !important;
        border: 0;

        i {
          margin-right: 10px;
        }

        &:hover {
          filter: brightness(95%);
        }
      }

      #mc-embedded-subscribe{
        margin-top: 10px;
        float: right;
      }
    }

  }

  .login-portlet {
    border-radius: 5px;
    //overflow: hidden;
    background: white;
    margin: auto auto 15px auto;
    width: 100%;
    max-width: 800px;
    overflow: hidden;
    @extend .shadow;

    .logo {
      background: white;
      border-bottom: 1px solid $border-line;
      padding: 15px 30px;
      display: flex;
      flex-direction: column;
      border-radius: 10px 10px 0 0;
      min-height: 106px;

      span {
        display: block;
        color: white;
        text-align: center;
        margin-top: 15px;

        i {
          margin-left: 15px;
        }
      }

      img {
        margin: auto;
        max-width: 285px;
        width: 100%;
      }
    }

    @media (max-width: 576px) {
      .logo{
        height: 45px;
        padding: 10px;

        img{
          height: 60px !important;
          width: 100%;
        }
      }
    }

    .form-wrapper {
      display: flex;
      min-height: 500px;
      padding-top: 15px;
      padding-bottom: 15px;
      color: $default-text-color;

      > form {
        padding: 0 15px;
        margin: auto;
        width: 100%;
        max-width: 600px;

        .invalid{
          color: #dc3545;
        }

        .valid{
          color: #28a745;
        }

        .invalid-crow{
          border: 1px solid #dc3545;
        }

        .crown-holder{
          width: 35px;
          display: flex;
          background: #f39821;
          color: white;
          border-radius: 0 3px 3px 0;
          i{
            margin: auto;
          }
        }

        .form-group {
          margin-bottom: 25px;
          padding-left: 30px;
          position: relative;

          &:before {
            display: flex;
            width: 25px;
            border: 1px solid $orange;
            background: $orange;
            color: white;
            height: 25px;
            position: absolute;
            left: -4px;
            top: calc(50% + 7px);
            line-height: 18px;
            padding: 2px 7px;
            border-radius: 100%;
            box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
            0 1px 3px 1px rgba(60, 64, 67, 0.15);
          }
        }

        .form-group:nth-child(1):before {
          content: "1";
        }

        .form-group:nth-child(2):before {
          content: "2";
          top: 45px;
        }

        .form-group:nth-child(3):before {
          content: "3";
        }

        input {
          font-family: Roboto-Regular, serif;
        }

        input:focus {
          box-shadow: none;
          border: 1px solid $orange;
        }

        button:disabled{
          background-color: #828a91 !important;
          cursor: not-allowed;
        }

        button {
          transition: all 0.5s ease-in-out;
          background-color: $orange !important;
          border: 0;

          i {
            margin-right: 10px;
          }

          &:hover {
            filter: brightness(95%);
          }
        }
      }

      @media (max-width: 400px) {

        > form {
          .form-group {
            padding-left: 0;

            &:before {
              display: none;
            }
          }
        }
        .avatar-picker {
          max-height: 142px;
          overflow-y: auto;
        }
      }

    }
  }

  .accordion>.card {
    border-radius: 0 !important;
    .card-header{
      background: whitesmoke;
    }
  }
}

.avatar-picker {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  grid-auto-rows: 1fr;
  border: 1px solid #ced4da;
  padding: 5px;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  @extend .scrollbar;

  .avatar-item {
    width: 40px;
    height: 40px;
    border: 1px solid #ced4da;
    min-width: 40px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;

    &.active {
      background: $orange;
      border: 1px solid $orange;
      box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    }

    &:hover {
      border: 1px solid $orange;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: 0 5px;
    }
  }

}



.invitation-error{
  max-width: 600px;
  margin: auto;
  padding-top: 15px;
  padding-left: 45px !important;
  padding-right: 15px !important;
  .alert{
    padding: 15px 40px 15px 15px !important;
  }
}

.data-protection{
  width: 100%;
  max-width: 800px;
  display: flex;
  margin: 0 auto auto auto;

  .col-12{
    text-align: center;
    a:hover{
      color: #6c757d !important;
    }
    a{
      color: $orange !important;
    }
  }

  .col-12:first-child{
    text-align: right;
  }
  .col-12:last-child{
    text-align: left;
  }

  @media (max-width: 575px) {
    .col-12:first-child{
      text-align: center;
      margin-bottom: 10px;
    }
    .col-12:last-child{
      text-align: center;
      margin-top: 10px;
    }
  }

}



.recovery-wrapper{
  .countDownWrapper{
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    height: 5px;
  }

  .custom-form-row{
    display: flex;
    button{
      margin: auto 0 auto auto;
    }
  }

  .alert-feedback{
    border-top-left-radius: 0 !important;
  }

  .countDown{
    position: absolute;
    top: 0;
    width: 0;
    animation: countingDown 5s linear 1;
    background: #6bca6c;
    height: 5px;
    border-radius: 3px 3px 0 0;
    animation-delay: 0.5s;
  }

  @keyframes countingDown {
    0% {
      width: 100%;
    }

    100% {
      width: 0;
      display: none;
    }
  }
}
