@import "../../../styles/global";
.timer-controls{
  background: white;

  .form-row{
    span{
      font-weight: 800;
      font-family: Roboto-Black, serif;
      font-size: 20px;
    }
  }
  .form-group{
    margin-bottom: 0 !important;
  }

  button{
    margin:0 5px;
    position: relative;
    border: 1px solid #ced4da !important;
  }

  input:focus,  input:active {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid $orange !important;

    img{
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    }

  }

  .fa-stop{
    color: #ff004e;
  }

  .fa-play{
    color: #28a745;
  }


}

.popover{
  border: 1px solid #d1d1d1;
  margin: 0;
  top: 7px !important;
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after, .bs-popover-bottom>.arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: white;
}


.timer-wrapper{
  position: relative;
  height: 50px;
  button{
    margin: 0;
    background: transparent !important;
    height: 50px;
    width: 79px;
    min-width: 79px;
    padding: 0;
    display: block;
    font-family:  Roboto, serif;
    img{
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 50px;
      left: 0;
      top: 0;
    }

    h2.smallTime{
      font-size: 15px !important;
      padding: 4px 0;
      width: 70%;
      white-space: initial;
    }

    h2{
      font-size: 22px;
    }

    h6, h2{
      margin: auto;
      position: relative;
      z-index: 1;
      width: 100%;
      text-align: center;
      color: black;
    }
  }

  button:focus,  button:active,  button:visited{
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;

    img{
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    }

  }

}

.bell{
  display:block;
  height: 50px;
  width: 100%;

  -webkit-animation: ring 4s .7s ease-in-out;
  -webkit-transform-origin: 50% 35px;
  -moz-animation: ring 4s .7s ease-in-out;
  -moz-transform-origin: 50% 35px;
  animation: ring 4s .7s ease-in-out;
  transform-origin: 50% 35px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}
