@import "../../../styles/global";



.collect-vote-wrapper{
  border: 1px solid #ced4da;
  //margin-top: 15px;
  background-color: #e6e6e6;
  border-radius: 5px;
  position: relative;

  .hidden-message-collect{
    filter: grayscale(0.7);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 1;
    left: 0;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238d8d8d' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

}
.vote-wrapper{
  display: flex;
  flex-direction: column;
  padding: 10px;

  .vote-option, .vote-counting{
    display: flex;
    width: 100%;
  }

  .vote-counting{
    //border-top: 1px solid #ced4da;
    width: calc(100% - 50px);
    display: flex;
    margin-top: 5px ;

    .vote-supporters{
      width: 100%;
    }

    img{
      float: right;
      margin-top: 5px;
    }

    >div{
      border: 2px solid black;
      margin-top: 5px;
      border-radius: 0 5px 5px 0;
      height: 10px;
      .vote-percentage{
        position: relative;
        left: calc(100% + 15px);
        top: -10px;
        font-size: 16px;
        color: rgb(0, 134, 182);
        font-family: Roboto-Black, serif;
        text-shadow: 1px 1px 1px black;
      }
    }
    >div:nth-child(1){
      background: rgb(0, 134, 182);
    }
    >div:nth-child(2){
      background: rgb(239, 71, 122);
    }
  }

  &:not(:last-child){
    border-bottom: 1px solid #ced4da;
  }

  .vote-action{
    button{
      width: 100px;
    }
  }



  .voter-avatar{
    width: 40px;
    height: 40px;
    transition: all 0.5s ease-in-out;
    border: 2px solid #e6e6e6;
    z-index: 10;
    background: white;
    border-radius: 100%;
    object-fit: contain;
    object-position: 0 3px;

    margin: auto 20px auto auto;
  }

  .vote-content{
    padding: 5px 10px 5px 0;
    width: 100%;
    display: flex;
    span{
      margin: auto 0;
    }
  }
}



//background: rgb(212, 219, 38);
//background: rgb(239, 71, 122);
//background: rgb(255, 191, 39);
//background: rgb(0, 185, 241);
//background: rgb(237, 30, 36);
//background: rgb(255, 243, 42);
//background: rgb(245, 166, 195);
//background: rgb(22, 170, 180);
//background: rgb(238, 71, 54);
//background: rgb(0, 144, 208);
//background: rgb(138, 82, 161); color: rgb(138, 82, 161); height: 50%;
//background: rgb(0, 134, 182);
