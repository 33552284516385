@import "../../../styles/global";
.poll-list-wrapper{

  .description{
    padding-top: 15px;
    margin-bottom: 15px;
  }

  .modal-body{
    padding-top: 0;
  }

  .pagination-header{
    display: flex;
    border-bottom: 1px solid #dee2e6;
    padding: 5px 0;
    font-size: 16px;
    span{
      color: $orange;
      font-weight: 900;
    }

    .poll-count{
      padding: 10px 10px 10px 0;
    }

    .page-detail{
      border-left: 1px solid #dee2e6;
      padding: 10px;
    }
  }

  .poll-item{
    background: #e6e6e6;
    padding: 10px 10px 0;
    margin-top: 10px;
    border-radius: 5px;

    .dropdown-toggle:disabled{
      background-color: #e9ecef !important;
      cursor: not-allowed;
      color: #495057 !important;
      opacity: 1;

      &:hover{
        background-color: #e9ecef !important;
        opacity: 1;
        filter: brightness(100%) !important;
      }
    }

    button:disabled{
      background-color: #e9ecef !important;
      cursor: not-allowed;
      color: #495057 !important;
      opacity: 1;

    }

    input:disabled{
      cursor: not-allowed;
      border: 1px solid #ced4da !important;
    }

    &.poll-active{
      border-radius: 5px 5px 0 0;
      margin-top: 10px;
    }

    form .form-row >div{
      margin-bottom: 5px;
    }

    .delete-poll-wrapper{
      max-width: 42px;
      width: 42px;
      button{
        background: transparent;
        border-radius: 100%;
        border: 0 solid !important;
      }
    }

    .poll-btn-config{
      background: white;
      border: 0 solid #ced4da !important;
      border-right: 1px solid #ced4da !important;
      color: #495057;

    }
  }

  .preview-deck-wrapper{
    display: grid;
    grid-gap: 5px 5px;
    gap: 5px 5px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: minmax(45px, 1fr);
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;

    &:before{
      content: "";
      width: 10px;
      border-left: 2px dotted #737373;
      border-bottom: 2px dotted #737373;
      position: absolute;
      height: 50px;
      left: 10px;
      top: -5px;
    }

    .card-preview{
      border: 1px solid #b4b4b4;
      background: #f3f3f3;
      border-radius: 5px;
      padding: 5px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      &.no-selected{
        opacity: 0.2;
        border: 1px solid white;
        transition: opacity 0.5s ease-in-out;
        &:hover{
          opacity: 1;
          border: 1px solid #b4b4b4;
        }
      }

      input[type="checkbox"]{
        appearance: none;
        border: 1px solid #b4b4b4;
        width: 15px;
        height: 15px;
        background-color: white;
        border-radius: 2px;
        position: absolute;
        cursor: pointer;

        &:checked {
          background-color: $orange;
          border-color: $orange;
          &:after{
            content: "\f00c";
            color: white;
            font-family: "Font Awesome 5 Free", serif;
            font-weight: 900;
            font-size: 10px;
            height: 13px;
            display: block;
            width: 13px;
            text-align: center;
            padding-top: 1px;
            position: absolute;
            top: 0;

          }
        }
      }


      img{
        width: 100%;
        height: calc(100% - 30px);
        object-fit: contain;
        padding: 5px;
        min-width: 30px;
        min-height: 25px;
        max-height: 40px;
        cursor: pointer;
      }

      span{
        text-align: center;
        display: block;
      }
    }
  }


  .results-poll-wrapper{
    padding: 10px;
    margin: 0;
    border: 1px solid $border-line;
    border-radius: 0 0 5px 5px;

    .voting-deck-wrapper{
      border: 0;
      background: white;
      padding: 0;
    }

    >.col{
      padding: 0;
    }
  }

  input:active, input:focus{
    border: 1px solid $orange !important;
    box-shadow: none;
    outline: 0;
  }

  .text-muted{
    padding-left: 20px;
  }

  .modal-90w{
    width: 90%;
    max-width: 900px;
    margin: auto;
  }

  .modal-body{
    .container-fluid{
      padding: 0;
    }
  }

  .select-deck-dropdown{
    width: 100%;

    &.show button{
      &.btn-primary.dropdown-toggle{
        border-color: orange !important;
        color: #495057;
      }
    }

    button{
      background-color: white !important;
      display: block;
      width: 100%;
      height: calc(1.5em + .75rem + 2px);
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      text-align: left;
      box-shadow: none !important;

      .drop-active-name{
        width: calc(100% - 15px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }

      &:after{
        float: right;
        position: relative;
        top: 8px;
      }


      &:active, &:focus, &.show .dropdown-toggle, &.dropdown-toggle:focus{
        border: 1px solid $orange !important;
        color: #495057 !important;
        box-shadow: none;
        outline: 0;
      }
    }

    .dropdown-menu.show{
      width: 100%;
      padding: 0;
      overflow: hidden;

      .dropdown-item:last-child{
        border-bottom: 0 solid $border-line;
      }

      .dropdown-item{
        padding: 6px;
        border-bottom: 1px solid $border-line;
      }

      .dropdown-item.active{
        background-color: $orange;
      }

    }

  }

  .deck-option{
    border: 1px solid $border-line;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    background-color: $border-line;

    &.disabled{
      filter: grayscale(1);
    }


    .deck-description-wrapper{
      p{
        margin-bottom: 0;
      }
      .info-collapsed{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      span{
        margin-bottom: 15px;
        color: $orange;
        font-weight: bold;
        cursor: pointer;

        &:hover{
          i{
            text-decoration: underline;
          }
          text-decoration: underline;
        }
      }
    }


    .preview-deck-wrapper{
      display: grid;
      gap: 5px 5px;
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
      grid-auto-rows: minmax(45px, 1fr);
      margin-top: 15px;

      .card-preview{
        border: 1px solid #b4b4b4;
        background: #f3f3f3;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        img{
          width: 100%;
          height: calc(100% - 30px);
          object-fit: contain;
          padding: 5px;
          min-width: 30px;
          min-height: 25px;
          max-height: 40px;
        }

        span{
          text-align: center;
          display: block;
        }
      }
    }

  }
}

.collapser{
  cursor: pointer;
}

.behaviour-ready{
  display: block;
  color: #28a745;
  padding: 5px 10px;
}

.config-wrapper{
  display: flex;
  flex-direction: row;
  min-width: 85px;
  width: 85px;
  border: 1px solid #ced4da;
  background: white;
  margin-left: 10px;
  border-radius: 5px;
  overflow: hidden;

  button{
    border: 0 !important;
    border-right: 1px solid #ced4da !important;
    border-radius: 0;
  }
  .attr{
    margin: auto;
    color: #494f57db;
    font-size: 10px;
  }
}

.sm-100{
  /* Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    width: 100%;
  }
}


.slider-voted{
  background-color: #f39820 !important;
  border-color: #f39820 !important;
  color: white;
}

