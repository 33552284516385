@import "../../../styles/global";

.guest-list-wrapper{
    transition: all 0.5s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: relative;
    z-index: 1;
    height: calc(100% - 3px);
    background: #f6f6f6;
    @extend .scrollbar;

    @media (max-width: 800px) {
      padding-right: 15px !important;
    }

    display: grid;
    gap: 15px 15px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: minmax(60px, 1fr);

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 576px) {
      padding: 31px 15px 15px;
      grid-gap: 15px;
      gap: 15px 15px;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      grid-auto-rows: minmax(55px, 1fr);

      &.small-tile{
        grid-auto-rows:55px !important;
      }

    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (max-width: 768px) {
      padding: 31px 15px 15px;
      grid-gap: 15px;
      gap: 15px 15px;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      grid-auto-rows: minmax(55px, 1fr);

      &.small-tile{
        grid-auto-rows:55px !important;
      }

      &.medium-tile{
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;
        grid-auto-rows:80px !important;
      }

    }

    /* Large devices (desktops, 992px and up) */
    @media (max-width: 992px) {
      padding: 31px 15px 15px;
      grid-gap: 15px;
      gap: 15px 15px;
      grid-auto-rows: minmax(55px, 1fr);

      &.small-tile{
        grid-auto-rows:55px !important;
      }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (max-width: 1200px) {
      padding: 31px 15px 15px;
      grid-gap: 15px;
      gap: 15px 15px;


      &.small-tile{
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        grid-auto-rows:60px !important;
      }
      &.medium-tile{
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-auto-rows: minmax(180px, 1fr);
      }
    }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    padding: 31px 15px 15px;
    grid-gap: 15px;
    gap: 15px 15px;


    &.small-tile{
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-auto-rows:60px !important;
    }
  }

}
