@import "../../../styles/global.scss";

.tile-wrapper{
  width: 100%;
  height: 100%;
  display: flex;

  .guest-card-wrapper {
    transition:  all 0.5s ease-in-out;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 300px;
    max-height: 360px;
    margin: auto;
    border: 1px solid $border-line;
    border-radius: 5px;

    .oder-badge{
      position: absolute;
      top: 5px;
      width: 26px;
      height: 26px;
      z-index: 2;
      border-radius: 5px;
      text-align: center;
      color: white;
      background: black;
    }

    .mood{
      width: 26px;
      height: 26px;
      position: absolute;
      top: 5px;
      right: 5px;
      @extend .shadow;
      border-radius: 5px;
      z-index: 3;
    }

    .you{
      position: absolute;
      top: 5px;
      left: 5px;
      width: 34px;
      height: 20px;
      color: white;
      background: #28a745;
      z-index: 2;
      text-align: center;
      border-radius: 15px;
      font-size: 12px;
      padding: 1px;
      text-shadow: 0 0 1px #c1c1c1;
    }

    .front-face{
      background: white;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-content: stretch;
      padding: 10px;

      .avatar-wrapper{
        transition: all 0.5s ease-in-out;
        position: relative;
        border-radius: 100%;
        background: white;
        top: 0;
        border: 1px solid $border-line;
        width: calc(100% - 20px);
        margin: auto;
        z-index: 1;

        .avatar-image{
          width: 100%;
          height: 100%;
          padding: 0;
          object-position: 0 3px;
          border-radius: 100%;
          z-index: 2;
          position: relative;

        }

        .crown{
          position: absolute;
          width: 50px;
          top: -15px;
          left: calc(50% - 25px);
          z-index: 0;
          transition:  all 0.5s ease-in-out;
          filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
        }

        .santa{
          position: absolute;
          width: 80px;
          height: 80px;
          top: -35px;
          left: calc(50% - 35px);
          z-index: 3;
          transition:  all 0.5s ease-in-out;
        }
      }

      .name{
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0 0;
        padding-top: 5px;
      }

    }

    .back-face{
      position: relative;
      width: 100%;
      height: 100%;
      top: -100%;
      opacity: 0;
      border-radius: 5px;
      background: #000000a1;
      padding: 10px;
      z-index: 3;
      display: flex;
      flex-wrap: wrap;


      button{
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;
        border-radius: 100%;
        margin: auto;
        background: $orange;
        border: 1px solid $orange;
        @extend .shadow;
      }
    }

  }

  .guest-card-wrapper.active{
    border: 1px solid $border-active;
    background: $border-active;
    box-shadow: 0 1px 2px rgba(60,64,67, 0.3), 0 1px 3px 1px rgba(60,64,67, 0.15);

    .front-face{
      background: $border-active;
      display: flex;
      justify-content: stretch;
      align-content: stretch;

      .avatar-wrapper{
        transition: all 0.5s ease-in-out;
        top: -30px;
        margin: 0 auto;
        border: 2px solid $border-line;
        width: 46px;
        z-index: 10;
        background: white;

        .avatar-image{
          z-index: 15;
        }
        .crown{
          position: absolute;
          width: 26px;
          top: -15px;
          left: calc(50% - 13px);
          z-index: 25;
          filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7));
        }

        .santa{
          display: none;
        }

      }

      .name{
        margin: auto 0 0;
      }

      .action-card{
        display: flex;
        margin: auto;
        width: 100%;
        height: 100%;

        .active-action-card{
          width: 100%;
          height: 100%;
          filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
          object-fit: contain;
          animation: show 1s ease-in-out;
        }
      }

      @keyframes show {
        0%   { opacity: 0; }
        100% { opacity: 1; }
      }
    }
  }

  .guest-card-wrapper:hover{
    .back-face{
      opacity: 1;
    }
  }

  .guest-card-wrapper.isParticipant{
    .back-face{
      display: none;
    }
  }

  &.small-tile{
    .guest-card-wrapper{
      .mood{
        width: 15px;
        height: 15px;
        right: -1px;
        top: -1px;
        z-index: 1;
      }

      .you{
        transition: all 0.5s ease-in-out;
        padding: 0;
        left: 0;
        top: 0;
        text-align: center;
        width: 30px;
        z-index: 1;
        height: 15px;
        font-size: 10px;
        line-height: 14px;
      }

      .front-face{
        flex-direction: row;
        padding: 0 5px 15px 5px;
        position: relative;

        .avatar-wrapper {
          transition: all 0.5s ease-in-out;
          width: 30px;
          min-width: 20px;
          min-height: 20px;
          margin: auto auto auto auto;
          top: 0;
          left: 0;
          border-width: 0;
          border-radius: 100%;
          background: transparent;
          padding: 1px;
          z-index: 1;
          opacity: 0.4;

          &.your-avatar{
            opacity: 1;
          }

          img{
            transition: all 0.5s ease-in-out;
            border-radius: 100%;
          }
          .crown{
            width: 18px;
            height: 18px;
            left: 6px;
            top: -8px;
          }
          .santa{
            width: 18px;
            height: 18px;
            left: 6px;
            top: -8px;
          }
        }
        .name{
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          padding: 5px 5px 0 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
          text-align: center;

        }
      }
      .back-face{
        padding: 0;
        button{
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          border-radius: 100%;
          margin: auto;
          background: $orange;
          border: 1px solid $orange;
          @extend .shadow;
        }
      }
    }

    .guest-card-wrapper.active{

      .you{
        transition: all 0.5s ease-in-out;
        padding: 0;
        left: 0;
        top: 18px;
        text-align: center;
        width: 30px;
        z-index: 2;
        height: 15px;
        font-size: 10px;
        line-height: 14px;
      }

      .front-face{
        .avatar-wrapper {
          transition: all 0.5s ease-in-out;
          width: 35px;
          min-width: 35px;
          min-height: 35px;
          margin: 0 0 auto 0;
          top: -8px;
          left: -8px;
          border-color: $orange;
          background: white;
          border-width: 1px;
          border-radius: 100%;
          opacity: 1;
          img{
            //transition: all 0.5s ease-in-out;
            border-radius: 100%;
          }

          .crown{
            width: 20px;
            height: 20px;
            left: 6px;
            top: -10px;
            z-index: 0;
          }

          .santa{
            width: 20px;
            height: 20px;
            left: 6px;
            top: -10px;
            z-index: 0;
          }
        }

        .action-card{
          position: absolute;
          right: 0;
          width: calc(100% - 35px);
          padding: 0 7px 0 3px;
          height: calc(100% - 20px);
          top: 0;
          z-index: 1;
        }

        .name{
          width: calc(100%);
          text-align: left;
        }


      }
      .back-face{}
    }
  }






  //******* MEDIA QUERIES ***********//

  /* Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {

  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (max-width: 768px) {


    &.medium-tile{
      .guest-card-wrapper{
        .mood{
          width: 15px;
          height: 15px;
          right: -1px;
          top: -1px;
          z-index: 1;
        }

        .you{
          transition: all 0.5s ease-in-out;
          padding: 0;
          left: 0;
          top: 0;
          text-align: center;
          width: 30px;
          z-index: 1;
          height: 15px;
          font-size: 10px;
          line-height: 14px;
        }

        .front-face{
          flex-direction: row;
          padding: 0 5px 15px 5px;
          position: relative;
          width: 100%;

          .avatar-wrapper {
            transition: all 0.5s ease-in-out;
            width: 30px;
            min-width: 20px;
            min-height: 20px;
            margin: auto auto auto auto;
            top: 0;
            left: 0;
            border-width: 0;
            border-radius: 100%;
            background: transparent;
            padding: 1px;
            z-index: 1;
            opacity: 0.4;

            &.your-avatar{
              opacity: 1;
            }

            img{
              transition: all 0.5s ease-in-out;
              border-radius: 100%;
            }
            .crown{
              width: 18px;
              height: 18px;
              left: 6px;
              top: -8px;
            }
            .santa{
              width: 18px;
              height: 18px;
              left: 6px;
              top: -8px;
            }
          }
          .name{
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            padding: 5px 5px 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 10px;
            text-align: center;

          }
        }
        .back-face{
          padding: 0;
          button{
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 100%;
            margin: auto;
            background: $orange;
            border: 1px solid $orange;

          }
        }
      }

      .guest-card-wrapper.active{

        .you{
          transition: all 0.5s ease-in-out;
          padding: 0;
          left: 0;
          top: 18px;
          text-align: center;
          width: 30px;
          z-index: 2;
          height: 15px;
          font-size: 10px;
          line-height: 14px;
        }

        .front-face{
          .avatar-wrapper {
            transition: all 0.5s ease-in-out;
            width: 35px;
            min-width: 35px;
            min-height: 35px;
            margin: 0 0 auto 0;
            top: -8px;
            left: -8px;
            border-color: $orange;
            background: white;
            border-width: 1px;
            border-radius: 100%;
            opacity: 1;
            img{
              transition: all 0.5s ease-in-out;
              border-radius: 100%;
            }

            .crown{
              width: 20px;
              height: 20px;
              left: 6px;
              top: -10px;
              z-index: 0;
            }
            .santa{
              width: 20px;
              height: 20px;
              left: 6px;
              top: -10px;
              z-index: 0;
            }

          }

          .action-card{
            position: absolute;
            right: 0;
            width: calc(100% - 30px);
            padding: 0 7px 0 3px;
            height: calc(100% - 15px);
            top: 0;
            z-index: 1;
          }

          .name{
            width: calc(100%);
            text-align: center;
          }


        }
        .back-face{}
      }
    }

  }



  /*
  !* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint *!
  @media (max-width: 768px) {
    .guest-card-wrapper{
      .mood{}
      .front-face{

      }
      .back-face{}
    }
  }

  !* Large devices (desktops, 992px and up) *!
  @media (max-width: 992px) {
    .guest-card-wrapper{
      .front-face{

      }
      .back-face{}
    }
  }

  !* Extra large devices (large desktops, 1200px and up) *!
  @media (max-width: 1200px) {
    .guest-card-wrapper{
      .front-face{

      }
      .back-face{}
    }
  }
  */


}






/*
.app-wrapper {



    !* ----------- iPhone 5, 5S, 5C and 5SE ----------- *!

    !* Portrait and Landscape *!
    @media only screen
    and (min-device-width: 320px)
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2) {

    }

    !* Portrait *!
    @media only screen
    and (min-device-width: 320px)
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: portrait) {

      .guest-list-wrapper{
        padding: 20px 10px 15px;
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;

        .tile-wrapper{
          width: 33.333333333333333333%;
          padding: 5px 5px 10px 5px;
          .guest-card-wrapper{
            height: 150px;

            .avatar-wrapper {
              width: 80px;
              height: 80px;
            }

          }

          .guest-card-wrapper.active{
            height: 150px;
            .you{
              transition: all 0.5s ease-in-out;
              left: calc(100% - 45px);
              width: 40px;
            }
            .avatar-wrapper{
              width: 45px;
              height: 45px;
              top: -5px;
              left: -5px;
              margin: 0;
              z-index: 0;
            }
            .active-action-card{
              position: relative;
              top: -5px;
              width: 100%;
              margin: 0 auto;
            }
          }

        }

!*        .guest-card-wrapper{
          margin-bottom: 10px;
          width: 40%;
          .front-face{
            .avatar-wrapper{
              width: 50px;
            }
            .action-card{
              width: 60px;
            }
          }
        }*!



      }

      .deck-wrapper{
        height: 404px !important;
        min-height: 404px !important;



      }
    }

    !* Landscape *!
    @media only screen
    and (min-device-width: 320px)
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: landscape) {

    }


  }
}
*/

