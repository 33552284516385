@import "../../../styles/global";

.join-screen-wrapper{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-repeat: repeat;
  background-size: 250px;
  background-color: #fdfdfd;
  display: flex;

  >.row{
    margin: auto !important;
  }

}

.join-login-wrapper{
  padding-top: 10px;
  padding-bottom: 10px;

  h1, h2, h3{
    text-align: center;
  }

  .ownerName{
    color: $orange;
    font-weight: bold;
  }


  .form-wrapper {
    display: flex;
    min-height: 500px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: $default-text-color;
    flex-direction: column;

    > form {
      background: white;
      box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
      padding: 50px 40px;
      margin: auto;
      width: 100%;
      max-width: 600px;
      border: 1px solid $border-line;
      border-radius: 5px;

      .invalid{
        color: #dc3545;
      }

      .valid{
        color: #28a745;
      }

      .invalid-crow{
        border: 1px solid #dc3545;
      }

      .crown-holder{
        width: 35px;
        display: flex;
        background: #f39821;
        color: white;
        border-radius: 0 3px 3px 0;
        i{
          margin: auto;
        }
      }

      .form-group {
        margin-bottom: 25px;
        padding-left: 30px;
        position: relative;

        &:before {
          display: flex;
          width: 25px;
          border: 1px solid $orange;
          background: $orange;
          color: white;
          height: 25px;
          position: absolute;
          left: -4px;
          top: calc(50% + 7px);
          line-height: 18px;
          padding: 2px 7px;
          border-radius: 100%;
          box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
          0 1px 3px 1px rgba(60, 64, 67, 0.15);
        }
      }

      .form-group:nth-child(1):before {
        content: "1";
      }

      .form-group:nth-child(2):before {
        content: "2";
        top: 45px;
      }

      .form-group:nth-child(3):before {
        content: "3";
      }

      input {
        font-family: Roboto-Regular, serif;
      }

      input:focus {
        box-shadow: none;
        border: 1px solid $orange;
      }

      button:disabled{
        background-color: #828a91 !important;
        cursor: not-allowed;
      }

      button {
        transition: all 0.5s ease-in-out;
        background-color: $orange !important;
        border: 0;

        i {
          margin-right: 10px;
        }

        &:hover {
          filter: brightness(95%);
        }
      }
    }
  }
}
