@import "../../../styles/global";
.sidebar-wrapper{
  transition: width 0.5s ease-in-out;
  width: 320px;
  min-width: 320px;
  overflow: hidden;
  background: $background-deck;
  position: relative;
  z-index: 2;
  @extend .shadow;

  &.closed{
    transition: width 0.5s ease-in-out;
    width: 60px;
    min-width: 60px;
    .hamburger-toggle{
      margin-right: 3px;
    }
  }

  .sidebar-header{
    background: #3c3e42;
    padding: 5px 15px;
    height: 36px;

      .hamburger-toggle{
        font-size: 22px;
        display: inline-block;
        width: 1em;
        height: 1em;
        padding: 0;
        margin-top: 2px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        vertical-align: middle;
        border: 0 none;
        background: transparent;
        float: right;

        &:before, &:after, .icon{
          content: " ";
          display: block;
          width: 100%;
          height: .2em;
          margin: 0 0 .2em;
          transition: transform .2s ease-in-out;
          -moz-transition: transform .2s ease-in-out;
          -webkit-transition: transform .2s ease-in-out;
          border-radius: .25em;
          -moz-border-radius: 25em;
          -webkit-border-radius: .25em;
          background: $background-deck;
        }

        &.closed{
          transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);

          &:before{
            width: .6em;
            transform: translateX(.4em) translateY(.2em) rotate(45deg);
            -moz-transform: translateX(.4em) translateY(.2em) rotate(45deg);
            -webkit-transform: translateX(.4em) translateY(.2em) rotate(45deg);
          }

          &:after{
            width: .6em;
            transform: translateX(.4em) translateY(-.2em) rotate(-45deg);
            -moz-transform: translateX(.4em) translateY(-.2em) rotate(-45deg);
            -webkit-transform: translateX(.4em) translateY(-.2em) rotate(-45deg);
          }
        }
      }
  }

  .sidebar-actions{
    height: calc(100% - 36px);
    overflow-y: auto;
    overflow-x: hidden;
    align-content: baseline;
    @extend .scrollbar;

    .owner-action-item{
      padding: 5px;
      border-bottom: 1px solid $border-line;
      display: flex;
      cursor: pointer;
      transition: all 0.3s linear;
      width: 100%;
      height: 45px;

      &:hover{
        color: white;
        background: $orange;
      }

      img{
        max-height: 35px;
        object-fit: contain;
        width: 38px;
        height: 30px;

        margin: 0;
      }

      .label{
        line-height: 16px;
        display: block;
        margin: auto auto auto 0;
      }

      &.condensed{
        background: #cacaca;

        &:hover{
          color: white;
          background:  #cacaca;
        }
        img{
          width: 100%;
          padding: 3px 0;
          height: 32px;
          max-height: 32px;
          background: white;
          border-radius: 5px;
          border: 1px solid white;
          transition: all 0.5s ease-in-out;

          &.active{
            background-color: $orange;
            transition: all 0.5s ease-in-out;
          }
        }
      }

    }

  }








}
