@import "../../../styles/global";

.link-sharing-wrapper.modal{
  .modal-body{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}


.new-badge{
  position: relative;
  top: -3px;
}

.download-blob{
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 1);
  animation: pulse-blacks 2s infinite;
}

  @keyframes pulse-blacks {
    0% {
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
    }

    70% {
      box-shadow: 0 0 0 5px rgba(40, 167, 69, 0);
    }
  }

.link-sharing-wrapper{

  .container-fluid{
    padding: 0;
  }


  .step-sharing{
    max-width: 80px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;

    div{
      margin: auto;
      border: 1px solid #a3a8ad;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      display: flex;
      background: #a3a8ad;
      color: white;

      span{

        margin: auto;
        display: block;
      }
    }

  }

  h5{
    font-family: Roboto-Light, serif;
    margin-bottom: 15px;
  }

  .link-sharing{
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #e6e6e6;
    margin-top: 5px;
    transition: all 0.5s ease-in-out;


    input, input:focus, input:active{
      border: 0;
      box-shadow: none;
      cursor: pointer;
      background: white !important;
    }

    .fa-clone{
      float: right;
      position: absolute;
      right: 27px;
      top: calc(50% - 12px);
      color: #a3a8ad;
      padding: 8px 8px 8px 15px;
      font-size: 12px;
      background: rgb(255,255,255);
      background: linear-gradient(45deg, rgba(255,255,255,0.5550595238095238) 0%, rgba(255,255,255,1) 50%);
    }

    .i-copied{
      float: right;
      position: absolute;
      right: 27px;
      top: calc(50% - 8px);
      color: $orange;
      padding: 6px;
      font-size: 12px;
      font-family: Roboto-Regular, serif;
      background: white;
      border-radius: 100% 0 0 100%;

    }


    &.copied{
      color: white;
      background: $orange;
    }

  }



}
