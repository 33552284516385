@import "../../../styles/global.scss";

.new-admin-wrapper{

  .modal-body{
    overflow: hidden;
    padding: 0;
    @extend .scrollbar;
    max-height: 460px;
    min-height: 460px;
    overflow-y: auto;
  }

  input:focus {
    box-shadow: none;
    border: 1px solid $orange;
  }

  .text-description{
    h3{
      font-family: Roboto-Light, serif;
    }
    p{
      font-size: 16px;
    }
  }

  .container{
    padding: 0;

  }

  .user-container{

  }

  .list-item-user{
    display: flex;

    .num{
      margin: auto 5px auto 0;
      font-size: 20px;
      font-family: Roboto-Light, serif;
      border-right: 1px solid rgba(0,0,0,.125);
      padding-right: 15px;
      height: 40px;
      padding-top: 10px;
    }

    .user{
      margin: auto auto auto 10px;
    }

    .btn-2{
      margin: auto 0 auto auto;

      &:hover{
        background: $orange;
        color: white;
        border-color: $orange;
      }
    }

    .btn-3{
      margin: auto 0 auto 10px;

      &:hover{
        background: $orange;
        color: white;
        border-color: $orange;
      }
    }
  }
  .avatar-item {
    width: 40px;
    height: 40px;
    border: 1px solid #ced4da;
    min-width: 40px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-position: 0 5px;
    }
  }
}
