.info-banner-wrapper{
  color: #5d5e5d;
  padding: 20px 50px 20px 20px;
  max-width: 715px;
  margin: 0 auto auto;

  img{
    width: 60px;
    height: 100%;

  }

  h5{
    color: #3ab54a;
    font-weight: bold;
    font-family: Roboto-Black;
  }

  p{
    margin-bottom: 0;
    font-size: 14px;
  }

}


.btn-banner {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 100px;
}
